import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MemberTable } from '../../components/Table/memberTable';
import TableDetail from '../../components/TableDetail/index';
import ApiCaller from '../../core/Api';
const mockData = {
  item: 'BJ－Retail－00001',
  city: '北京',
  address: '北京市朝阳区建外大街建国路91号',
  property: '零售',
  buildingArea: '1000,000,000',
  manageArea: '1000,000,000',
  openDate: '2020.08.08',
  cooperation: '债务融资／股权融资／股权合作／基金募资／出售',
  transaction: '资产交易／股权交易',
  owner: '国企／民营',
  type: '购物中心',
};

export const CommonMember = () => {
  const [showDetail, setShowDetail] = useState(null);
  const [data, setData] = useState([]); // vip & common

  useEffect(() => {
    ApiCaller.getProjects().then(res => setData(res.data));
  }, []);

  const handleShowDetail = value => {
    setShowDetail(value);
  };
  return (
    <Main>
      {!showDetail && <MemberTable data={data} handleShowDetail={handleShowDetail} />}
      {showDetail && <TableDetail data={showDetail} type={'common'} handleShowDetail={handleShowDetail} />}
    </Main>
  );
};

export const VipMember = () => {
  const [showDetail, setShowDetail] = useState(null);
  const [data, setData] = useState([]); // vip & common

  useEffect(() => {
    ApiCaller.getProjectsWithoutCurrentWeek().then(res => setData(res));
  }, []);

  const handleShowDetail = value => {
    setShowDetail(value);
  };
  return (
    <Main>
      {!showDetail && <MemberTable data={data} handleShowDetail={handleShowDetail} />}
      {showDetail && <TableDetail data={showDetail} handleShowDetail={handleShowDetail} type={'vip'} />}
    </Main>
  );
};

const Main = styled.div`
  width: 100%;
  padding: 0 44px 35px;
  max-height: 690px;
  overflow: auto;
  background-color: #fff;
  border-radius: 0px 10px 10px 10px;
`;
