import React, { useState } from 'react';
import styled from 'styled-components';
import ApiCaller from '../../core/Api/index';
import { parseCitycode } from '../../core/utils/index';

export const MemberTable = ({ data = [], handleShowDetail }) => {
  return (
    <Table>
      <thead className="header">
        <tr className="tr">
          <th className="text-center">编号</th>
          <th className="text-right">城市</th>
          <th className="text-right">类型</th>
          <th className="text-right">项目编号</th>
          <th className="text-right">合作模式</th>
          <th className="text-right">发布时间</th>
          <th>备注</th>
        </tr>
      </thead>
      <tbody className="body">
        {data.length > 0 &&
          data.map((value, index) => (
            <tr onClick={() => handleShowDetail(value)}>
              <td className="text-center">{index + 1}</td>
              <td className="text-right">{parseCitycode[value.projectinfo.citycode]}</td>
              <td className="text-right">{value.projectinfo.projectType}</td>
              <td className="text-right">{value._id}</td>
              <td className="text-right">{value.projectinfo.operationMode}</td>
              <td className="text-right">{value.projectinfo.openingDate}</td>
              <td>無</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .header {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    background-color: #fff;
    padding: 20px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
    th {
      background-color: #fff;
      padding: 20px 15px;
      position: sticky;
      top: 0;
      font-weight: 600;
    }
  }
  .body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    color: #333333;
    cursor: pointer;
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      td {
        padding: 20px 15px;
        align-items: center;
      }
    }
  }
`;
